/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #555;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    margin: 0 10px 30px 0;
  }
}

/* --------- asc styles --------- */
#header {
  // background: rgba(255, 255, 255, 0.8);
  a.logo {
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      margin-right: 5px;
      width: auto;
      height: auto;
      max-width: 60px;
    }
    span.big,
    span.small {
      font-size: 1.5em;
      color: #555;
      line-height: 1em;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
      color: $theme-primary;
    }
    span.small {
      font-size: 14px;
      font-family: var(--font-family);
      font-weight: 600;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
      color: $theme-primary;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: $theme-primary;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  .dropdown-list {
    margin-top: 15px;
    display: none;
    clear: right;
    float: right;
    width: 100%;
    max-width: 280px;
    z-index: 100;
    span.dropdown {
      border: 1px solid #d8d8d8;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
#banner-wrap {
  background: #def;
}
#banner {
  h1 {
    color: #333;
  }
  ul {
    color: #333;
    font-size: 1.2rem;
    font-weight: 600;
    li:before {
      color: #e94c48;
      font-weight: normal;
    }
  }
}
.card {
  &.light-blue {
    background-color: #f7fbfe;
    border-color: #c5e2f0;
  }
}
.cta-wrap {
  background-color: $theme-primary;
  color: #fff;
  .cta-text {
    width: 90%;
  }
  .cta-btn-wrap {
    width: 90%;
  }
  .cta-btn {
    border: 3px solid #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header {
    a.logo {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
  .cta-wrap {
    .cta-btn-wrap {
      width: 220px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
  }
  #header a.logo {
    img {
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      font-size: 1.9em;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  .cta-wrap {
    .cta-text {
      width: auto;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
  }
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #banner-wrap {
    background: #fff;
  }
  #banner {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background: #fff url(../images/banner-bg.jpg) no-repeat 0 -30px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
